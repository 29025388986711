import { useMemo } from 'react';
import { Contract } from '@ethersproject/contracts';
import { JsonRpcProvider, JsonRpcSigner } from '@ethersproject/providers'
import { AddressZero } from '@ethersproject/constants';
import { useWeb3React } from '@web3-react/core'

import { isAddress } from 'utils'
import MULTICALL_ABI from 'config/abi/payboltMulticall.json';
import PAYBOLT_ABI from 'config/abi/paybolt.json';
import PAYBOLT_BRIDGE_ABI from 'config/abi/payboltBridge.json';
import PAYBOLT_BRIDGE_V2_ABI from 'config/abi/payboltBridgeV2.json';

import {
  MULTICALL_ADDRESSES,
  PAYBOLT_ADDRESSES,
  PAYBOLT_BRIDGE_ADDRESSES,
  PAYBOLT_BRIDGE_V2_ADDRESSES,
} from 'constants/addresses';

import { getSouschefContract } from 'utils/contractHelpers'

export function getContract(address: string, ABI: any, provider: JsonRpcProvider, account?: string): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(address, ABI, getProviderOrSigner(provider, account) as any)
}
// account is not optional
function getSigner(provider: JsonRpcProvider, account: string): JsonRpcSigner {
  return provider.getSigner(account).connectUnchecked()
}
// account is optional
function getProviderOrSigner(provider: JsonRpcProvider, account?: string): JsonRpcProvider | JsonRpcSigner {
  return account ? getSigner(provider, account) : provider
}

// returns null on errors
export function useContract<T extends Contract = Contract>(
  addressOrAddressMap: string | { [chainId: number]: string } | undefined,
  ABI: any,
  withSignerIfPossible = true
): T | null {
  const { provider, account, chainId } = useWeb3React()

  return useMemo(() => {
    if (!addressOrAddressMap || !ABI || !provider || !chainId) return null
    let address: string | undefined
    if (typeof addressOrAddressMap === 'string') address = addressOrAddressMap
    else address = addressOrAddressMap[chainId]
    if (!address) return null
    try {
      return getContract(address, ABI, provider, withSignerIfPossible && account ? account : undefined)
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [addressOrAddressMap, ABI, provider, chainId, withSignerIfPossible, account]) as T
}

export function useInterfaceMulticall() {
  return useContract(MULTICALL_ADDRESSES, MULTICALL_ABI, false);
}

export function usePayboltContract() {
  return useContract(PAYBOLT_ADDRESSES, PAYBOLT_ABI, true);
}

export function usePayboltBridgeContract() {
  return useContract(PAYBOLT_BRIDGE_ADDRESSES, PAYBOLT_BRIDGE_ABI, true);
}

export function usePayboltBridgeV2Contract() {
  return useContract(PAYBOLT_BRIDGE_V2_ADDRESSES, PAYBOLT_BRIDGE_V2_ABI, true);
}

export const useSousChef = (id) => {
  const { provider, chainId, account } = useWeb3React()
  return useMemo(() => getSouschefContract(id, getProviderOrSigner(provider, account), chainId), [id, provider, chainId, account])
}
